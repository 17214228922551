import React from 'react';

export default function SkeletonBlogCards() {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-20'>
      {[...Array(4)].map((_i, index) => (
        <div
          key={index}
          className='relative flex flex-col overflow-hidden'
        >
          <div className='animate-pulse'>
            <div className='rounded-2xl w-full h-[200px] lg:h-[330px] bg-slate-500'></div>
            <div className='flex flex-col py-4 h-60'>
              <h3 className='relative text-xl text-white bg-slate-500 w-full h-40 rounded-xl'></h3>
              <p className='relative mt-4 text-base text-gray-600 bg-slate-500 h-full rounded-xl' />
              {/* <div className="mt-4 flex items-end justify-between gap-4">
                                <span className="h-10 w-1/2 rounded animate-pulse bg-slate-500"></span>
                                <p className="h-10 w-1/2 rounded text-xs lg:text-sm bg-slate-500"></p>
                            </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
