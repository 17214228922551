import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function BlogCard({ node, fallbackImage }) {
  const {
    timeToRead,
    excerpt,
    frontmatter: { title, description, date, slug, localHeaderImage },
    headerImage
  } = node;
  const postTitle = title || slug;
  const blogImage = localHeaderImage || headerImage || fallbackImage;

  return (
    <Link to={`${slug}`}>
      <div
        className='flex flex-col overflow-hidden transition ease-linear group'
        key={slug}
      >
        <div className='rounded-3xl overflow-hidden'>
          <GatsbyImage
            image={getImage(blogImage)}
            alt=''
            imgStyle={{ borderRadius: '24px' }}
          />
        </div>
        <div className='flex flex-col py-4 h-full'>
          <h3
            className='text-2xl sm:text-2xl lg:text-3xl lg:leading-[38px] font-light text-light-grey lg:min-h-[76px] h-full flex items-center justify-start group-hover:text-highlight transition ease-linear my-3'>
            {postTitle}
          </h3>
          <p
            className='mt-4 text-lg md:text-xl lg:text-2xl line-clamp-3'
            dangerouslySetInnerHTML={{
              __html: description || excerpt
            }}
          />
          {/* <div className="mt-6 flex items-end h-full justify-between">
                        <p className="text-sm lg:text-sm mb-3">
                            {date} | {timeToRead} mins read
                        </p>
                    </div> */}
        </div>
      </div>
    </Link>
  );
}
