import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import * as queryString from 'query-string';
import { SearchIcon } from '@heroicons/react/outline';

import InputField from '../atoms/inputs/InputField';
import BlogCard from '../atoms/blog/BlogCard';
import ResultCard from '../atoms/blog/ResultCard';
import SkeletonBlogCards from '../atoms/blog/SkeletonBlogCards';
import BlogFilters from '../atoms/blog/BlogFilters';
import Button from '../atoms/inputs/Button';

import useDebounce from '../../utils/hooks/useDebounce';

export default function SearchPosts({ posts, localSearchBlog, location }) {
  const [isLoading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState('');
  const debouncedQueryTerm = useDebounce(query, 500);
  const { search } = queryString.parse(location.search);
  const [postsToShow, setPostsToShow] = useState(4);
  const [activeFilter, setActiveFilter] = useState('All');
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
  const [resultsToRender, setResultsToRender] = useState([]);
  const [postsToRender, setPostsToRender] = useState([]);
  const [hideLoadMore, setHideLoadMore] = useState(false);

  const data = useStaticQuery(graphql`
        query FallbackImageQuery {
            fallbackImage: file(name: { eq: "blog-header-default" }) {
                id
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

  const results = useFlexSearch(
    query,
    localSearchBlog.index,
    localSearchBlog.store
  );

  const handleShowMorePosts = () => {
    setPostsToShow(postsToShow + 2);
  };

  // Flexsearch query logic
  useEffect(() => {
    setLoading(true);
    setQuery(value);
  }, [value]);

  useEffect(() => setLoading(false), [debouncedQueryTerm]);

  useEffect(() => {
    if (search) setValue(search);
  }, [search]);

  // Filter Posts by Frontmatter `category` property
  useEffect(() => {
    if (activeFilter !== 'All') {
      setFilteredResults(
        results.filter((post) => post.category === activeFilter)
      );
      setFilteredBlogPosts(
        posts.filter(
          (post) => post.node.frontmatter.category === activeFilter
        )
      );
    } else {
      setFilteredResults(results);
      setFilteredBlogPosts(posts);
    }
  }, [results, posts, activeFilter]);

  // Render `x` amount of posts based on `postsToShow` variable
  useEffect(() => {
    setPostsToRender(filteredBlogPosts.slice(0, postsToShow));
    setResultsToRender(filteredResults.slice(0, postsToShow));
  }, [filteredBlogPosts, filteredResults, postsToShow]);

  // Reset postToShow on filter or if input change occurs
  useEffect(() => setPostsToShow(4), [value, activeFilter]);

  // Hide load more button if `postsToShow` > Array.length
  useEffect(() => {
    if (value && postsToShow >= filteredResults.length) {
      setHideLoadMore(true);
      return;
    }

    if (!value && postsToShow >= filteredBlogPosts.length) {
      setHideLoadMore(true);
      return;
    }

    setHideLoadMore(false);
  }, [postsToShow, filteredBlogPosts, filteredResults]);

  return (
    <section>
      <h2 className='font-light text-highlight text-3xl md:text-4xl lg:text-5xl mb-6 md:mb-8 lg:mb-10'>
        Articles
      </h2>
      <div className='flex w-full justify-start mb-6 md:mb-8 lg:mb-20'>
        <div className='flex relative rounded-lg max-w-lg h-12 w-full'>
          <SearchIcon
            className='absolute z-10 top-1/2 transform -translate-y-1/2 left-3 text-grey-25'
            width={16}
          />
          <InputField
            id='search'
            type='search'
            hasIcon
            placeholder='Search articles'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            styles=''
          />
        </div>
      </div>
      <div className='mb-10'>
        <BlogFilters
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      </div>
      <div className='w-full mt-10 md:min-h-128'>
        {isLoading ? (
          <SkeletonBlogCards />
        ) : (
          <>
            {query ? (
              resultsToRender.length > 0 ? (
                <ResultsCardList
                  results={resultsToRender}
                  fallbackImage={data.fallbackImage}
                />
              ) : (
                <ErrorResults />
              )
            ) : (
              <BlogCardList
                posts={postsToRender}
                fallbackImage={data.fallbackImage}
              />
            )}
          </>
        )}
      </div>
      <div className='w-full flex items-center justify-center max-w-sm px-4 my-10 lg:mt-20 mx-auto'>
        {!hideLoadMore && (
          <Button
            label='View more'
            className=''
            onClickHandler={() => handleShowMorePosts()}
          />
        )}
      </div>
    </section>
  );
}

function BlogCardList({ posts, fallbackImage }) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-20 lg:gap-y-24'>
      {posts.map(({ node }, index) => (
        <BlogCard
          key={index}
          node={node}
          fallbackImage={fallbackImage}
        />
      ))}
    </div>
  );
}

function ResultsCardList({ results, fallbackImage }) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-20 lg:gap-y-24'>
      {results.map((node, index) => (
        <ResultCard
          key={index}
          node={node}
          fallbackImage={fallbackImage}
        />
      ))}
    </div>
  );
}

function ErrorResults() {
  return (
    <p
      className='mx-auto max-w-xs md:max-w-lg lg:max-w-4xl lg:text-lg text-gray-400 text-center'
      style={{ textAlign: 'center', minHeight: '100px' }}
    >
      Sorry, couldn't find any posts matching this search.
    </p>
  );
}
